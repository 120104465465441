.root {
  max-width: 500px;
  margin: 0 auto;
}

.fetching {
  color: transparent;
}

.spinner {
  position: absolute;
  left: calc(50% - 9px);
}

.link {
  color: #b20000;

  &:hover,
  &:focus {
    color: #900000;
  }
}
