@import 'styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(320px, max-content));
  gap: 32px;
  margin-bottom: 32px;
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fill, minmax(260px, max-content));
  }
}

.wrapper {
  max-width: 600px;
  margin: auto;
  text-align: center;
}
.appWrapper{
  width: 100%;
  margin: auto;

}
.contentWrapper {
  font-size: 18px;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Maven Pro', 'sans-serif';
  color: #253450;
}
.contentInner {
  display: flex;
  max-width: 768px;
  width: 768px;
  margin: 0 auto;
  padding: 0;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-grow: 1;
  -moz-flex-shrink: 0;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  text-align: center;
  overflow: hidden;
}
.title {
  font-size: 2.2222222222222em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #253450;
}
.paragraf {
  text-align: center;
  color: #000000;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.6;
  letter-spacing: 0px;
  font-weight: 500;
}